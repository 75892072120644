
/*=============================================
=            checkout            =
=============================================*/


/*-- Checkout Title --*/
.checkout-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;

  margin-bottom: 30px;

  text-decoration: underline;
  text-transform: capitalize;
}

/* Overwrite step color */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: black;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: black;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: black;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: black;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: black;
}


/*-- Checkout Form --*/
.checkout-form {
  & label {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: $black--two;
  }

  & select {
    font-size: 14px;
    line-height: 23px;

    width: 100%;
    height: 45px;
    margin-bottom: 15px;
    padding: 10px 20px 10px 0;

    color: $grey--seven;
    border: none;
    border-bottom: 2px solid $grey--eleven;
    border-radius: 0;
    background-color: $white;
    &::after {
      right: 20px;

      width: 6px;
      height: 6px;

      border-width: 1px;
      border-color: $grey--seven;
    }
    & .current {
      line-height: 23px;

      display: block;

      color: $grey--seven;
    }
    & .list {
      width: 100%;
    }

    &:hover {
      border-color: $black--two;
    }
  }

  & input {
    font-size: 14px;
    line-height: 23px;

    width: 100%;
    margin-bottom: 15px;
    padding: 10px 20px;

    color: $grey--seven;
    border: 1px solid $grey--six;
    border-radius: 0;
    background-color: $white;
    &[type="checkbox"] {
      width: auto;
    }
  }

  & .check-box {
    float: left;

    margin-right: 70px;

    &:last-child {
      margin-right: 0;
    }

    & input[type="checkbox"] {
      display: none;

      & + label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        position: relative;

        margin: 0;
        padding-left: 30px;

        color: $black;
        &::before {
          position: absolute;
          top: 0;
          left: 0;

          display: block;

          width: 20px;
          height: 20px;

          content: "";
          transition: all 0.3s ease 0s;

          border: 2px solid $grey--six;
        }
        &::after {
          font-size: 12px;
          line-height: 20px;

          position: absolute;
          top: 0;
          left: 0;

          display: block;

          width: 20px;

          transition: all 0.3s ease 0s;
          text-align: center;

          opacity: 0;
          color: $black;
        }
      }

      &:checked + label {
        &::before {
          border: 2px solid $black;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  padding: 45px;

  background-color: $grey--twentyTwo;

// Responsive

  @include respond(small-mobile) {
    padding: 30px;
  }

  & h4 {
    font-weight: 700;
    line-height: 23px;

    flex-basis: 18px;
    &:first-child {
      margin-top: 0;
      margin-bottom: 25px;
    }
    &:last-child {
      margin-top: 15px;
      margin-bottom: 0;
    }
    & span {
      display: block;
      float: right;
    }
  }
  & ul {
    border-bottom: 1px solid $grey--six;
    & li {
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;

      display: block;

      margin-bottom: 16px;

      color: $grey--seven;
      & span {
        float: right;

        color: $black;
      }
    }
  }
  & p {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;

    margin: 0;
    padding: 10px 0;

    color: $grey--twentyThree;
    border-bottom: 1px solid $grey--six;
    & span {
      float: right;
    }
  }
}

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  padding: 45px;

  background-color: $grey--twentyTwo;

// Responsive

  @include respond(small-mobile) {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  & input[type="radio"] {
    display: none;

    & + label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      position: relative;

      margin: 0;
      padding-left: 30px;

      color: $black;
      &::before {
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 20px;
        height: 20px;

        content: "";
        transition: all 0.3s ease 0s;

        border: 2px solid $grey--six;
      }
      &::after {
        position: absolute;
        top: 5px;
        left: 5px;

        display: block;

        width: 10px;
        height: 10px;

        content: "";
        transition: all 0.3s ease 0s;
        text-align: center;

        opacity: 0;
        background-color: $black;
      }
    }

    &:checked + label {
      &::before {
        border: 2px solid $black;
      }
      &::after {
        opacity: 1;
      }
    }
  }

  & input[type="checkbox"] {
    display: none;

    & + label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      position: relative;

      margin: 0;
      padding-left: 30px;

      color: $grey--seven;
      &::before {
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 16px;
        height: 16px;

        content: "";
        transition: all 0.3s ease 0s;

        border: 2px solid $grey--six;
      }
      &::after {
        position: absolute;
        top: 4px;
        left: 4px;

        display: block;

        width: 8px;
        height: 8px;

        content: "";
        transition: all 0.3s ease 0s;
        text-align: center;

        opacity: 0;
        background-color: $black;
      }
    }

    &:checked + label {
      &::before {
        border: 2px solid $black;
      }
      &::after {
        opacity: 1;
      }
    }
  }

  & p {
    font-size: 14px;
    line-height: 23px;

    display: none;

    margin-top: 8px;

    color: $grey--seven;
  }
}

/*-- Place Order --*/
.place-order {
  font-weight: 400;
  line-height: 24px;

  float: left;

  width: 140px;
  height: 36px;
  margin-top: 40px;
  padding: 6px 20px;

  text-transform: uppercase;

  color: $white;
  border: none;
  background-color: $black;

  &:hover {
    background-color: $white;
  }
}

/*=====  End of checkout  ======*/

