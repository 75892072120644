// fonts
$work-sans: "proxima", sans-serif;

// color
$black: #000;
$black--two: #333;
$black--three: #222;
$white: #fff;
$grey: #7e7e7e;
$grey--two: #d6d6d6;
$grey--three: #e7e7e7;
$grey--four: #f8f8f8;
$grey--five: #d1d1d1;
$grey--six: #999999;
$grey--seven: #777777;
$grey--eight: #d0d0d0;
$grey--nine: #dcdcdc;
$grey--ten: #eeeeee;
$grey--eleven: #ccc;
$grey--twelve: #aaaaaa;
$grey--thirteen: #dddddd;
$grey--fourteen: #696969;
$grey--fifteen: #ababab;
$grey--sixteen: #d8d8d8;
$grey--seventeen: #e0e0e0;
$grey--eighteen: #ededed;
$grey--nineteen: #e8e8e8;
$grey--twenty: #e6e6e6;
$grey--twentyOne: #f7f7f7;
$grey--twentyTwo: #f2f2f2;
$grey--twentyThree: #505050;
$grey--twentyFour: #f9f9f9;
$grey--twentyFive: #f0ddd6;
$grey--twentySix: #878787;
$grey--twentySeven: #9b9b9b;
$grey--twentyEight: #ebebeb;
$grey--twentyNine: #f4f4f4;
$grey--thirty: #abb8c0;
$red--one: #d3122a;
$red--two: #ff0000;
$red--three: #c61932;
$red--four: #d11830;
$green--one: #98d8ca;
$green--two: #edf6eb;
$green--three: #a3d39c;
$blue--one: #1e73be;
$blue--two: #ecf4fb;
$blue--three: #7eb7e6;
$blue--four: #6d7f96;
$yellow--one: #dd9933;
$yellow--two: #f5cc26;
$yellow--three: #f8f5de;
$yellow--four: #ddb759;
$theme-color--default: $black;
$theme-color--heading: $black;
$transition--default: all 0.3s;


// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend !default;

// Path is relative to the stylesheets directory.
$assets-path: '../' !default;

// Typefaces
// =============================================================================
$font-sans-serif: Arial, sans-serif;

// Typography
// =============================================================================
// Base
$font-size: 16px;
$line-height: 24px / $font-size;
$font-family: $font-sans-serif;
$color: #222222;
// Headings
$font-size-h1: 13.5vw !default;
$font-size-h2: 32px !default;
$font-size-h3: 24px !default;
$font-size-h4: 16px !default;
$font-size-h5: 16px !default;
$font-size-h6: 15px !default;
$line-height-h: $line-height;
// Weights
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Transitions
// =============================================================================
$speed: 0.3s;
$bounce: cubic-bezier(0.17, 0.67, 0.3, 1.33);
$Power1EaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$Power2EaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$Power3EaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$Power4EaseOut: cubic-bezier(0.23, 1, 0.32, 1);
$Power1EaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$Power2EaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$Power3EaseIn: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$Power4EaseIn: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseIn: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);
$SineEaseOut: cubic-bezier(0.39, 0.575, 0.565, 1);
$SineEaseIn: cubic-bezier(0.47, 0, 0.745, 0.715);
$Power1EaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$Power3EaseInOut: cubic-bezier(0.77, 0, 0.175, 1);
$Power4EaseInOut: cubic-bezier(0.86, 0, 0.07, 1);
$SlowEaseOut: cubic-bezier(0.04, 1.15, 0.4, 0.99);
$easing: $Power2EaseOut;

// Spacing Units
// =============================================================================
$unit: 60px;
$unit-small: 40px;

// Container
// ==========================================================================
$container-width: 1440px;
$padding: $unit;

// Breakpoints
// =============================================================================
$from-tiny: 500px !default;
$to-tiny: $from-tiny - 1 !default;
$from-small: 700px !default;
$to-small: $from-small - 1 !default;
$from-medium: 1000px !default;
$to-medium: $from-medium - 1 !default;
$from-large: 1200px !default;
$to-large: $from-large - 1 !default;
$from-big: 1400px !default;
$to-big: $from-big - 1 !default;
$from-huge: 1600px !default;
$to-huge: $from-huge - 1 !default;
$from-enormous: 1800px !default;
$to-enormous: $from-enormous - 1 !default;
$from-gigantic: 2000px !default;
$to-gigantic: $from-gigantic - 1 !default;
$from-colossal: 2400px !default;
$to-colossal: $from-colossal - 1 !default;

//refactor landing.scss


// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #ffffff;
$black: #272727;
$dark: #121212;
// Specific
// =============================================================================
// Link
$link-color: $black;
$link-focus-color: $black;
$link-hover-color: $white;
// Selection
$selection-text-color: $white;
$selection-background-color: $black;

// Social Colors
// =============================================================================
$facebook-color: #3b5998;
$instagram-color: #e1306c;
$youtube-color: #cd201f;
$twitter-color: #1da1f2;

// Customs
$beige: #faebe3;

$primary: #3b3d44; //dark elf
$primary-dark: #202327;
$accent-dark: #af9175;
$bcg: #efeeee;
$accent-1: #41444e; //MacKintosh
$accent-2: #6c737f; //Pompei
$accent-3: #7a7a84; // Tarnished
$accent-4: #a8abb3; //Mischka
$accent-5: #595f70; //Grisalle

$light-1: rgba(250, 236, 229, 0.2);
$light-2: #f2f2f2;
$light-3: #dcdde0;




