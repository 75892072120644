
/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb {
  margin-bottom: 0;
  padding: 0;

  border-radius: 0;
  background-color: transparent;

  &__list {
    li {
      position: relative;

      display: inline-block;

      margin-right: 30px;
      &:after {
        position: absolute;
        right: -15px;

        content: "/";
      }

      &:last-child {
        margin-right: 0;

        color: $black--two;
        &::after {
          display: none;
        }
      }
    }
  }

  &__title {
    font-size: 48px;
    line-height: 1.3;

    margin-bottom: 20px;

    color: $black--two;

@include respond(extra-large-mobile) {
      font-size: 35px;
    }

@include respond(large-mobile) {
      font-size: 30px;
    }

@include respond(extra-small-mobile) {
      font-size: 25px;
    }
  }
}

/*=====  End of breadcrumb  ======*/

