
/*=============================================
=            sidebar            =
=============================================*/

.search-widget {
  position: relative;

  input {
    font-size: 14px;
    line-height: 19px;

    width: 100%;
    height: 40px;
    margin: 0;
    padding-right: 20px;
    padding-left: 0;

    border: none;
    border-bottom: 2px solid $grey--eleven;
    background: transparent;
    background-clip: padding-box;

    &:hover {
      border-color: $black--two;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 0;

    padding: 0;

    transform: translateY(-50%);

    border: none;
    background-color: transparent !important;

    &:hover {
      svg {
        color: $black--two;
      }
    }

    svg {
      font-size: 18px;
      line-height: 40px;

      color: $grey--fifteen;
    }
  }
}


/*----------  single sidebar widget  ----------*/

.single-sidebar-widget {
  &__title {
    font-size: 24px;
    line-height: 1.2;

    color: $black--two;
  }

  &__list {
    li {
      font-size: 15px;

      padding: 10px 0;
      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
      button {
        position: relative;

        padding: 0;

        text-transform: capitalize;

        color: $grey--six;
        border: none;
        background: none;
        &:after {
          position: absolute;
          right: 0;
          bottom: 0;
          left: auto;

          width: 0;
          height: 1px;

          content: "";
          transition: 0.3s;

          background-color: $black--two;
        }

        &:hover,
        &.active {
          color: $black--two;

          &:after {
            right: auto;
            left: 0;

            width: 100%;
          }
        }
      }
    }



    &--category {
      li {
        padding: 0;
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &--color {
      display: flex;
      li {
        margin-right: 15px;
        padding: 0;
        button {
          width: 24px;
          height: 24px;
          margin-bottom: 15px;

          cursor: pointer;

          border-radius: 50%;

          &:after {
            display: none;
          }

          &:hover,
          &.active {
            box-shadow: 0 0 0 2px $white, 0 0 0 3px rgba($black , 0.3);
          }
        }
        &:last-child {
          margin-right: 0;
          button {
            border: 1px solid $grey;
          }
        }
      }
    }
  }
}



.tag-container {
  button,
  a {
    font-size: 15px;
    line-height: 1.5;

    position: relative;

    display: inline-block;

    padding: 0;

    color: $grey;
    border: none;
    background: none;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;

      display: block;

      width: calc(100% - 18px);
      height: 1px;

      content: "";
      transition: opacity 0.5s ease;

      opacity: 0;
      background-color: $black--two;
    }

    &:after {
      display: inline-block;

      margin: 0 5px;

      content: "/";

      color: $grey--six;
    }

    &:hover,
    &.active {
      &:before {
        opacity: 0.2;
      }
    }
  }
}


.single-widget-post {
  display: flex;

  margin-bottom: 25px;
  padding-bottom: 25px;

  border-bottom: 1px solid $grey--three;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    border-bottom: none;
  }

  .image {
    flex-basis: 100px;
  }

  .content {
    flex-basis: calc(100% - 100px);

    padding-left: 20px;

    .widget-post-title {
      font-size: 15px;
      line-height: 24px;

      a {
        color: $black--two;
      }
    }
  }
}


/*=====  End of sidebar  ======*/

