/*=============================================
=            cta            =
=============================================*/

/* image cta */
.image-cta {
  &__tags {
    ul {
      li {
        display: inline-block;

        margin-right: 20px;
        a {
          font-size: 18px;
          line-height: 23px;

          display: block;

          padding-bottom: 3px;

          color: $grey;
          border-bottom: 1px solid transparent;

          &:hover {
            border-bottom: 1px solid $grey--two;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__content {
    h2 {
      font-size: 40px;
      line-height: 1.3;

@include respond(large-mobile) {
        font-size: 30px;
      }

@include respond(extra-small-mobile) {
        font-size: 20px;
      }
    }
  }
}

.image-cta-two {
  &__content {
    .subtitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.3;

      color: $black--two;
    }

    .title {
      font-size: 48px;
      font-weight: 400;
      line-height: 1.3;

      width: 350px;
      max-width: 100%;

      color: $black--two;

@include respond(large-mobile) {
        font-size: 40px;
      }

@include respond(extra-small-mobile) {
        font-size: 35px;
      }
    }

    .text {
      line-height: 1.5;
    }
  }

  &__image {
    img {
      @include respond(large-desktop) {
        max-width: 100%;
      }

@include respond(small-desktop) {
        max-width: 100%;
      }

@include respond(extra-large-mobile) {
        max-width: 100%;
      }

@include respond(large-mobile) {
        max-width: 100%;
      }
    }
  }
}

/*=====  End of cta  ======*/
