@import url("./assets/css/ionicons.min.css");
@import url("./assets/css/linearicons.css");
@import url("./assets/css/animate.css");
@import url("./assets/css/magnific-popup.css");
@import url("./assets/css/jquery.pagepiling.css");
@import url("./assets/css/owl.carousel.css");
@import url("./assets/css/settings.css");
@import url("./assets/css/layers.css");
@import url("./assets/css/navigation.css");
@import url("./assets/css/slick.css");

body {
  margin: 0;
  font-family: "proxima", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

/* Overwrite step color */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #d4ba84;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #d4ba84;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #d4ba84;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #d4ba84;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d4ba84;
}

.site-page-header-ghost-wrapper {
  background-color: #f5f5f5;
  padding: 24px;
}

.site-statistic-demo-card {
  background: #ececec;
  padding: 30px;
}

@font-face {
  font-family: "timberline";
  src: url("./assets/fonts/timberline/Timberline\ Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "times new roman";
  src: url("./assets/fonts/time/times\ new\ roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima";
  src: url("./assets/fonts/Proxima\ Nova\ Font.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "theano";
  src: url("./assets/fonts/TheanoDidot-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.box {
  position: relative;
  top: -224px;
  left: -164px;
  /* align-self: flex-end; */
  animation-duration: 2s;
  animation-iteration-count: infinite;

  /* height: 200px; */
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}
.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.move {
  animation-name: mymove;
}

.show-logo {
  animation-name: mymove;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes mymove {
  from {
    bottom: 0px;
  }
  to {
    top: 0px;
    opacity: 0;
  }
}

@keyframes appear {
  from {
    top: 0px;
  }
  to {
    bottom: 100px;
  }
}

input::placeholder {
  color: white;
}

/* @font-face {
  font-family: "revicons";
  src: url("../fonts/revicons/revicons90c6.eot");
  src: url("../fonts/revicons/revicons90c6.eot") format("embedded-opentype"),
    url("../fonts/revicons/revicons90c6.woff") format("woff"),
    url("../fonts/revicons/revicons90c6.ttf") format("truetype"),
    url("../fonts/revicons/revicons90c6.svg") format("svg");
  font-weight: normal;
  font-style: normal;
} */

blockquote {
  background: #f9f9f9;
  border-left: 10px solid rgb(212, 186, 132);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  /* quotes: "\201C""\201D""\2018""\2019"; */
}
blockquote:before {
  color: rgb(212, 186, 132);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: rgb(212, 186, 132);
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.test-field {
  color: #bf1650;
}

.test-field::before {
  display: inline;
  content: "⚠ ";
}

.single-sidebar-widget__list__categorie li button {
  position: relative;
  padding: 0;
  /* text-transform: capitalize; */
  color: #999999;
  border: none;
  background: none;
}

.single-sidebar-widget__list__categorie li button.active {
  /* text-transform: capitalize; */
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.single-sidebar-widget__list__categorie li button:hover {
  /* text-transform: capitalize; */
  color: #333;
  font-weight: bold;
  font-size: 15px;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-item-left {
  padding: 10px;
  flex: 50%;
}

.flex-item-right {
  padding: 10px;
  flex: 50%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  #book-page .book-title{
    font-size: 28px;
  }
}

.hero-slider-seven .hero-slider-seven__content > * {
  transition: all 1s ease 0s;
  opacity: 1;
}
